
// 获取bridge
function connectWebViewJavascriptBridge(callback) {
  if (window.WebViewJavascriptBridge) {
    callback(WebViewJavascriptBridge)
  } else {
    document.addEventListener('WebViewJavascriptBridgeReady', function () {
      callback(WebViewJavascriptBridge)
    }, false);
  }
}

// h5 调用 app 方法
function callHandler(eventName, options = {}) {
  if (!eventName) throw `请传入事件名称！`;
  return new Promise((resolve, reject) => {
    connectWebViewJavascriptBridge(bridge => {
      bridge.callHandler(eventName, options, (data) => {
        if (data && typeof data == 'string') data = JSON.parse(data)
        data.status === 'C001' ? resolve(data) : reject(data);
      })
    })
  })
}

// h5 调用 app 方法
function registerHandler(eventName) {
  return new Promise((resolve, reject) => {
    connectWebViewJavascriptBridge(bridge => {
      bridge.registerHandler(eventName, (data) => {
        if (data && typeof data == 'string') data = JSON.parse(data)
        resolve(data);
      })
    })
  })
}

// 用户登录信息\经纬度\当前城市\跳转系统设置页\授权保存图片\保存图片
const eventList = ['requestLogin', 'getGeoLocation', 'getUserCity', 'goToSetting', 'getAuth', 'startShare']
export const bridgeMethods = (function () {
  return eventList.reduce((prev, next) => {
    prev[next] = (options = {}) => {
      return callHandler(next, options)
    };
    return prev;
  }, {});
}());


// 监听回到h5页面
export const bridgeListenerBack = () => {
  return registerHandler('didBecomeActive');
}


