<template>
  <div id="app" class="app-index">
    <router-view></router-view>
    <!-- 透明背景, 没有授权展示 -->
    <div
      class="app-background"
      :class="{ 'app-show': noToken }"
      @click="handleRedirectUrl"
    ></div>
    <!-- 底部提示 -->
    <div class="app-footer_wrap" :class="{ 'app-show': noToken }">
      <div class="footer-content">
        <i class="icon-warn"></i>
        <span class="text">为了确保服务质量，该页面需授权昵称与头像信息</span>
        <i class="icon-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/cityApi";
import storage from "@/util/storage.js";
import { initConsole, updatePointData, getCallbackUrl } from "./util/index";
export default {
  name: "appIndex",
  computed: {
    noToken() {
      return (
        !window._ICC_LBS_DATA.token && !window._ICC_LBS_DATA.urlParams.isApp
      );
    },
  },
  created() {
    this.getPagePoint();
    // 测试环境可看console
    if (process.env.VUE_APP_NODE_ENV === "test") {
      initConsole().then(() => {
        new VConsole();
      });
    }

    if (window._ICC_LBS_DATA.token && process.env.NODE_ENV === "production") {
      this.getQrType();
      this.getWxConfig();
    }
  },
  methods: {
    handleRedirectUrl() {
      getCallbackUrl();
    },

    // 获取微信配置
    getWxConfig() {
      if (window._ICC_LBS_DATA.urlParams.isApp) return;
      window._ICC_LBS_DATA.isWXError = false;
      api
        .getLbsSignatureInfo({
          redirectUri: window.location.href.split("#")[0],
        })
        .then((res) => {
          this.initWxConfig(res.data);
        })
        .catch((err) => {
          this.$toast(err.errMsg);
        });
    },

    // 初始化配置
    initWxConfig(_item) {
      if (
        typeof _item === "string" &&
        process.env.VUE_APP_NODE_ENV !== "prod"
      ) {
        _item = JSON.parse(_item);
      }
      // eslint-disable-next-line no-undef
      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: _item.appId, // 必填，公众号的唯一标识
        timestamp: _item.timestamp, // 必填，生成签名的时间戳
        nonceStr: _item.nonceStr, // 必填，生成签名的随机串
        signature: _item.signature, // 必填，签名
        jsApiList: ["getLocation"], // 必填，需要使用的 JS 接口列表
      });

      // eslint-disable-next-line no-undef
      wx.error(function (err) {
        window._ICC_LBS_DATA.isWXError = true;
        // 暂无授权
        // if (err.errMsg === 'config:permission denied' || err.err_msg === 'config:permission denied')
        alert(JSON.stringify(err));
      });
    },

    // 判断type
    getQrType() {
      if (!window._ICC_LBS_DATA.urlParams) {
        window._ICC_LBS_DATA.urlParams = {};
      }
      if (!window._ICC_LBS_DATA.urlParams.qrCodeType) {
        this.qrCodeType();
      } else {
        this.getPointData();
      }
    },

    // 获取类型
    qrCodeType() {
      api
        .qrCodeType()
        .then((res) => {
          window._ICC_LBS_DATA.urlParams.qrCodeType = res.data;
          this.getPointData();
        })
        .catch((err) => {});
    },

    // 数据上报
    getPointData() {
      updatePointData({
        configId: "0aa0138eb4c211eb94440242ac120003",
        wxCorpId: process.env.VUE_APP_URL_WX_CORPID_MDL,
        bizParams: JSON.stringify(
          storage.getStorage("routerQuery", { channelSource: "", cid: "" })
        ),
        bizData: JSON.stringify({
          click: "首页",
          url: window.location.href || "",
          unionId: window._ICC_LBS_DATA.token,
          userAgent: navigator.userAgent || "",
          qrCodeType: window._ICC_LBS_DATA.urlParams?.qrCodeType || "",
        }),
      });
    },

    // 访问上报数据
    getPagePoint() {
      updatePointData({
        configId: "7ea3e833ad98f3845eefa603d21affa6",
        wxCorpId: window._ICC_LBS_DATA?.urlParams?.wxCorpId || "",
        bizParams: JSON.stringify(
          storage.getStorage("routerQuery", { channelSource: "", cid: "" })
        ),
        bizData: JSON.stringify({
          click: "页面访问上报",
          url: window.location.href || "",
          unionId: window._ICC_LBS_DATA?.token || "",
          qrCodeType: window._ICC_LBS_DATA?.urlParams?.qrCodeType || "",
        }),
      });
    },
  },
};
</script>

<style lang="less" scoped>
.app-index {
  min-width: 100%;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  overflow: hidden;
}

.app-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  z-index: 100;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
}

.app-footer_wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  padding: 20px 15px;
  overflow: hidden;
  z-index: 90;

  .footer-content {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 12px;
    box-shadow: 0 2px 16px 1px rgba(151, 151, 151, 0.14);
    border-radius: 9px;
    background: #fff;

    .text {
      line-height: 14px;
    }

    .icon-warn {
      width: 12px;
      height: 12px;
      margin-right: 9px;
      background: url("https://icc-prod.oss-cn-shanghai.aliyuncs.com/icc-lbs3/icon-warn.png")
        no-repeat center;
      background-size: contain;
    }

    .icon-right {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-top: -7px;
      background: url("https://icc-prod.oss-cn-shanghai.aliyuncs.com/icc-lbs3/icon-arrow-bottom.png")
        no-repeat center;
      background-size: contain;
      transform: rotate(-90deg);
    }
  }
}

.app-show {
  display: block;
}
</style>
