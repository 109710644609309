/**
 * 缓存在本地
 * @param {Object} res.key 存储的key
 * @param {Object} res.data 存储的值
 * @param {Object} res.defaultValue 返回的默认值，如果value没有值的请况下
 */
function iccStorage() {
  return {
    _STORAGE_KEY: `ICC_LBS`,
    getLbsKey() {
      return `wx42cf5e73876fe3b9`;
    },

    /**
     * 判断是不是字符串 origin/restructure
     * @param {String} name
     */
    isString(name) {
      return typeof name === 'string';
    },

    /**
     * 删除本地缓存
     * @param {String} key 删除缓存的名字
     * @param {String} name 删除类型
     */
    setStorage(res, name = 'sessionStorage') {

      if (!this.isString(res.key)) throw new Error('请添加缓存的key');
      try {
        if (!name) name = 'sessionStorage';
        res.key = `${this._STORAGE_KEY}_${this.getLbsKey()}_${res.key.toLocaleUpperCase()}`;
        window[name].setItem(res.key, JSON.stringify(res.data));
      } catch (error) {
        return res.defaultValue || '';
      }
    },

    /**
     * 获取本地缓存
     * @param {String} key 缓存的key
     * @param {String} name 删除类型
     * @param {*} defaultValue  默认本地缓存
     */
    getStorage(key, defaultValue, name = 'sessionStorage') {
      if (!this.isString(key)) throw new Error('请添加缓存的key');
      key = `${this._STORAGE_KEY}_${this.getLbsKey()}_${key.toLocaleUpperCase()}`;
      try {
        if (!name) name = 'sessionStorage';
        const data = window[name].getItem(key) || '';
        if (data) return JSON.parse(data);
        if (defaultValue) return defaultValue;
        return '';
      } catch (error) {
        return defaultValue || '';
      }
    },

    /**
     * 删除本地缓存
     * @param {String} key 删除缓存的名字
     * @param {String} name 删除类型
     */
    removeStorage(key, name = 'sessionStorage', callBack = null) {
      if (!this.isString(key)) throw new Error('请添加缓存的key');
      key = `${this._STORAGE_KEY}_${this.getLbsKey()}_${key.toLocaleUpperCase()}`;
      try {
        if (!name) name = 'sessionStorage';
        window[name].removeItem(key);
        callBack && callBack();
      } catch (error) {
        callBack && callBack();
        console.error(error, 'removeStorage：失败');
      }
    },

    /**
     * 删除所有缓存
     * @param {Object} opt
     * @param {Function} callBack 回调
     */
    clearStorage(callBack = null) {
      try {
        window.localStorage.clear();
        window.sessionStorage.clear();
        callBack && callBack();
      } catch (error) {
        callBack && callBack();
        console.error(error, 'clearStorage：失败');
      }
    },
  };
}

export default iccStorage();
