import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import wx from 'weixin-js-sdk';
import "amfe-flexible";

import './styles/base.less'

import { Toast, Loading, Image as VanImage } from "vant";
Vue.use(Toast);
Vue.use(Loading);
Vue.use(VanImage);

// 去掉
if (process.env.VUE_APP_NODE_ENV === 'prod') {
  console.log = () => null;
}


Vue.prototype.WX = wx;
Vue.config.productionTip = false;


new Vue({
  router,
  // store,
  render: (h) => h(App),
}).$mount("#app");
