import req from '../request.js';
let api = {}

//加载拼音为key的城市
const list = function (data) {
  return req.post('/rest/city/loadPyCityInfos', data);
}
api.list = list

// 门店列表
const storeList = function (data) {
  return req.post('/rest/city/loadStoreByCity', data);
}
api.storeList = storeList;

// 群活码
const groupQrCode = function (wxCorpId, storeId) {
  return req.post(`/rest/member/getChatGroupInfo/${wxCorpId}/${storeId}`);
}
api.groupQrCode = groupQrCode;

// 渠道活码
const channelQrCode = function (storeId, data) {
  return req.post(`/rest/member/getUserQrCode/${storeId}`, data);
}
api.channelQrCode = channelQrCode;

// 构建授权地址
const AuthUrl = function (data) {
  if (process.env.VUE_APP_NODE_ENV !== 'prod') {
    // 测试环境
    console.warn('测试环境-/joinStore/buildWxAuthorizationUrl');
    return req.post('/rest/joinStore/buildWxAuthorizationUrl', data);
  }
  return req.post('/rest/buildWxAuthorizationUrl', data);
}
api.AuthUrl = AuthUrl;

// 授权信息
const Auth = function (data) {
  if (process.env.VUE_APP_NODE_ENV !== 'prod') {
    // 测试环境
    console.warn('测试环境-/joinStore/auth');
    return req.post('/rest/joinStore/auth', data);
  }
  return req.post('/rest/auth', data);
}
api.Auth = Auth;

//获取门店
const getSelect = function (data) {
  return req.post('/rest/store', data)
}
api.getSelect = getSelect;

//获取js签名
const getLbsSignatureInfo = function (data) {
  if (process.env.VUE_APP_NODE_ENV !== 'prod') {
    // 测试环境
    console.warn('测试环境-/joinStore/jsapiSignature');
    return req.post('/rest/joinStore/jsapiSignature', data)
  }
  return req.post('/rest/jsapiSignature', data)

}
api.getLbsSignatureInfo = getLbsSignatureInfo;

//所有门店列表
const arrStoreList = function () {
  return req.post('/rest/storeList')
}
api.arrStoreList = arrStoreList;


//数据埋点采集接口
const collect = function (data) {
  return req.post('/rest/buried/collect', data);
}
api.collect = collect;

//获取请求活码 是渠道还是群活码
const qrCodeType = function () {
  return req.post('/rest/getQrCodeType');
}
api.qrCodeType = qrCodeType;

//获取麦中用户信息
const getUserInfo = function (data) {
  return req.post('/rest/mdc/userInfo', data);
}
api.getUserInfo = getUserInfo;

export default api;
