import interceptors from "./interceptors";
const request = {};

const get = function(url, data) {
  return interceptors.get(url, {
    params: data,
  });
};
request.get = get;

const post = function(url, data) {
  return interceptors.post(url, data);
};
request.post = post;



export default request;
