import Vue from "vue";
import VueRouter from "vue-router";
import api from '@/api/cityApi/index'
import storage from "@/util/storage.js";
import { bridgeMethods } from '@/util/bridge.js';
import { getQueryString, getWxH5Login, redirectUrlByJoint, getDialogAuth } from "@/util/index.js";

Vue.use(VueRouter);

const homeIndex = () =>
  import(/* webpackChunkName: "index" */ "@/pages/index/index.vue");
const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "index",
      component: homeIndex,
    },
    {
      path: "/index",
      name: "index",
      component: homeIndex,
    },
    {
      path: "/city",
      name: "city",
      component: () =>
        import(/* webpackChunkName: "city" */ "@/pages/cityList/index.vue"),
    },
    {
      path: "*",
      name: "index",
      component: homeIndex,
    },
  ],
});

// 路由拦截
router.beforeEach((to, from, next) => {
  const urlParams = getQueryString()
  // 如果这个参数，清空本地缓存
  if (to.query.clearCache) {
    storage.clearStorage();
  }

  if (to.query._icc_test_url) {
    alert(window.location.href)
  }

  // 判断是否有token
  if (!window._ICC_LBS_DATA.token) {
    // return false;
    // 测试环境不走登录
    if (process.env.NODE_ENV === "development" && !urlParams.isApp) {
      window._ICC_LBS_DATA.token = "oqRfN6pe2ewAkNNb3-K2w_YqaVgs";
    } else {
      window._ICC_LBS_DATA.token = storage.getStorage(
        "token",
        "",
        "localStorage"
      );
    }
  }

  // 缓存所有参数
  if (to.query.channelSource || to.query.cid) {
    storage.setStorage({ key: "routerQuery", data: to.query });
  }

  // 缓存下参数
  if (!window._ICC_LBS_DATA.urlParams) {
    window._ICC_LBS_DATA.urlParams = urlParams;

    if (to.query._icc_test_query) {
      alert(JSON.stringify(urlParams))
    }
  }

  urlParams.isApp ? handleApp(next) : handleH5(to, next, urlParams)
});

export default router;

// 如果是app
function handleApp(next) {
  if (window._ICC_LBS_DATA.token) {
    return next();
  }

  // 没有token
  bridgeMethods.requestLogin().then(res => {
    const token = '1ea8b40267150c8bfad1cfece071d58e_';
    // : res.token
    return api.getUserInfo({ token })
  })
    .then(res => {
      if (!res.data) res.data = { unionId: '' }
      window._ICC_LBS_DATA.token = res.data.unionId;
      next();
    })
    .catch(err => {
      console.error(err, 'requestLogin');
    })
}


// 如果是H5
function handleH5(to, next, urlParams) {
  // 如果链接有code,并且有token,重定向
  if (window._ICC_LBS_DATA.token && urlParams.code) {
    return window.location.href = redirectUrlByJoint(urlParams);
  }

  // 如果没有token，也没有code，弹出提示框
  if (!window._ICC_LBS_DATA.token && !urlParams.code) {
    getDialogAuth(to.name);
  }


  // 如果没有token，有code,走登录
  if (!window._ICC_LBS_DATA.token && urlParams.code) {
    return getWxH5Login();
  }

  // 防止用户地址填错
  if (to.name === "city" && !to.query.isHome) {
    return next("home");
  }

  next();
}