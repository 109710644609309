import api from "@/api/cityApi";
import storage from "./storage";
import { Dialog } from "vant";

export const getUrlCode = () => {
  var url = location.search;
  // console.log(location)
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
};

/**
 * 微信H5重定向
 */
export function getCallbackUrl() {
  const urlParams = getQueryString();
  const redirectUrl = redirectUrlByJoint(urlParams);
  api
    .AuthUrl({ redirectUri: decodeURIComponent(redirectUrl) })
    .then((res) => {
      window.location.href = res.data;
    })
    .catch((err) => {
      console.error(err, "微信H5重定向：getCallbackUrl");
    });
}

/**
 * 微信H5登录
 * @returns
 */
export function getWxH5Login() {
  const urlParams = getQueryString();
  const redirectUrl = redirectUrlByJoint(urlParams);

  api
    .Auth({
      wxCode: urlParams.code,
      wxCorpId: process.env.VUE_APP_URL_WX_CORPID_MDL,
    })
    .then((res) => {
      if (res.data.unionId) {
        storage.setStorage({ key: "token", data: res.data.unionId }, "localStorage");
        window.location.href = redirectUrl;
      } else {
        window._ICC_LBS_DATA.token = null;
        storage.removeStorage("token", "localStorage", getCallbackUrl);
      }
    })
    .catch((err) => {
      if (err.errMsg && err.errMsg === "服务调用失败,access_token,openid返回为空") {
        window._ICC_LBS_DATA.token = null;
        storage.removeStorage("token", "localStorage", getCallbackUrl);
      }
    });
}

/**
 * 重定向地址
 * @param {*} urlParams 必填
 * @param {Boolean} isHome 是否默认首页
 * @returns
 */
export function redirectUrlByJoint(urlParams = { qrCodeType: "" }) {
  const { origin, pathname, hash } = window.location;
  const params = hash.split('?')[1];
  if (urlParams.qrCodeType) {
    return `${origin}${pathname}?qrCodeType=${urlParams.qrCodeType}#/${params ? '?' + params : ''}`;
  }
  return `${origin}${pathname}#/${params ? '?' + params : ''}`;
}

/**
 * 判断是否重定向
 */

export function getDialogAuth(name) {
  if (name === 'permissions' || name === '404') {
    Dialog.close();
    return false;
  }
  Dialog.alert({
    confirmButtonText: '去授权',
    className: 'dialog-alert_custom',
    message: '为了确保服务质量，该页面需授权微信昵称与头像信息',
  })
    .then(() => {
      getCallbackUrl();
    })
    .catch(() => { });
}


/**
 * 获取url得参数
 * @param {String} queryName 参数名字
 */
export const getQueryString = (name) => {
  const search = window.location.search.substring(1);
  const hash = window.location.hash.split("?")[1];
  const list = [];
  if (!search && !hash) return name ? "" : {};
  if (search) list.push(...search.split("&"));
  if (hash) list.push(...hash.split("&"));
  let item = null;
  const params = {};
  for (let i = 0; i < list.length; i++) {
    item = list[i].split("=");
    params[item[0]] = item[1] || null;
  }
  if (name) return params[name] || null;
  return params || null;
};

// 数据上报
export function updatePointData(data) {
  data.bizOps = window._ICC_LBS_DATA.token || "";
  api
    .collect(data)
    .then(() => {
      console.log("上报成功");
    })
    .catch((err) => {
      console.error(err);
    });
}

export const getLocation = async (data) => {
  // console.log(JSON.stringify(data, null, 1))
  return new Promise((resolve, reject) => {
    wx.config({
      debug: false, // 开启调试模式
      appId: data.appId, // 必填，公众号的唯一标识
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonceStr, // 必填，生成签名的随机串
      signature: data.signature, // 必填，签名，见附录1
      jsApiList: ["openLocation", "getLocation"],
    });
    wx.ready((res) => {
      wx.getLocation({
        success: function (res) {
          setPoint(res, "地理位置获取成功");
          resolve(res);
        },
        cancel: function (err) {
          setPoint(err, "地理位置获取失败");
          reject(err);
        },
        fail: (err) => {
          setPoint(err, "地理位置获取失败");
          reject(err);
        },
      });
    });
    wx.error((err) => {
      console.log("wx:err", err);
    });
  });
};

function setPoint(res, msg) {
  collectdata({
    configId: "e1229815b4c111eb94440242ac120003",
    // bizParams: JSON.stringify({ channelSource: sessionStorage.getItem('channelSource') || '' }),
    bizParams: sessionStorage.getItem("_ROUTE_QUERY_MDL_") || "",
    bizData: JSON.stringify({
      click: msg,
      wxConfig: res || "",
      url: window.location.href || "",
      userAgent: navigator.userAgent || "",
      qrCodeType: sessionStorage.getItem("qrCodeType") || "",
      info: JSON.parse(localStorage.getItem("info")) || {},
    }),
  });
}

/**
 * underscore 防抖函数，返回函数连续调用时，空闲时间必须大于或等于 wait，func 才会执行
 * @param {function} func 回调函数
 * @param {number} wait 表示时间窗口的间隔
 * @param {boolean} immediate 设置为 true 时，是否立即调用函数
 * @return {function} 返回客户调用函数
 */
export function debounce(func, wait, immediate = false) {
  let timeout, args, context, timestamp, result;
  let later = function () {
    // 现在和上一次时间戳比较
    let last = +new Date() - timestamp;
    // 如果当前间隔时间少于设定时间且大于0就重新设置定时器
    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      // 否则的话就是时间到了执行回调函数
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    context = this;
    args = arguments;
    // 获得时间戳
    timestamp = +new Date();
    // 如果定时器不存在且立即执行函数
    let callNow = immediate && !timeout;
    // 如果定时器不存在就创建一个
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      // 如果需要立即执行函数的话 通过 apply 执行
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

//埋点
export const collectdata = (data) => {
  const info = JSON.parse(localStorage.getItem("info"));
  let collectFormDate = {
    bizTime: formatDate(new Date(), "YMDhms"),
    wxCorpId: process.env.VUE_APP_URL_WX_CORPID_MDL,
    bizOps: info?.unionId || "",
  };
  let form = {};
  form = Object.assign(form, data, collectFormDate);
  api.collect(form).then(() => { });
};

/**
 * 时间格式化
 * @param {date | string | number} time 传入的时间戳 | 时间
 * @param {string} format 返回的格式化时间
 * @param {Boolean} isUnit 是否带有中文日期标识符
 * @param {string} symbol 返回格式数据的连接符 // 移动端必须使用 / ，不然时间会有问题，苹果和安卓手机的区别
 */
export function formatDate(time, format = "YMD", isUnit = false, symbol = "-") {
  const date = isDate(time);
  let year = date.getFullYear(),
    month = zero(date.getMonth() + 1),
    day = zero(date.getDate()),
    hour = zero(date.getHours()),
    minute = zero(date.getMinutes()),
    second = zero(date.getSeconds());

  // 带有中文符号日期时间
  if (isUnit) {
    const DATE_UNIT = {
      Y: { unit: "年", time: year },
      M: { unit: "月", time: month },
      D: { unit: "日", time: day },
      h: { unit: "时", time: hour },
      m: { unit: "分", time: minute },
      s: { unit: "秒", time: second },
    };
    return format.replace(/(Y|M|D|h|m|s)/g, (i) => {
      return DATE_UNIT[i].time + DATE_UNIT[i].unit;
    });
  } else {
    // 不带中文符号日期时间
    const date = [year, month, day].join(symbol);
    const time = [hour, minute, second].join(":");
    const FORMAT_TIME = {
      YM: year + symbol + month,
      YMD: date,
      YMDhms: date + " " + time,
      hms: time,
    };
    return FORMAT_TIME[format];
  }

  // 补零
  function zero(date) {
    return date < 10 ? "0" + date : date;
  }
}

export function isDate(time) {
  let date = null;
  if (Object.prototype.toString.call(time) === "[object Date]") {
    date = time;
  } else if (
    (typeof time !== "object" && typeof time === "string") ||
    typeof time === "number"
  ) {
    if (typeof time === "string") {
      time = time.replace(/-/g, "/");
    }
    let isDate = new Date(time);
    date = isNaN(isDate) ? new Date() : isDate;
  } else {
    date = new Date();
  }
  return date;
}

/**
 * 动态插入VConsole
 * @param {*} url 路径
 * @returns
 */
export function initConsole(url = "") {
  return new Promise(function (resolve, reject) {
    const el = document.createElement("script"); // 创建 script 元素
    el.src =
      url ||
      "https://icc-prod.oss-cn-shanghai.aliyuncs.com/icc_mini_images/vconsole.min.js"; // url 赋值
    el.async = false; // 保持时序
    const loadCallback = function () {
      // 加载成功回调
      el.removeEventListener("load", loadCallback);
      resolve();
    };
    const errorCallback = function (evt) {
      // 加载失败回调
      el.removeEventListener("error", errorCallback);
      var error = evt.error || new Error("Load javascript failed. src=" + url);
      reject(error);
    };
    el.addEventListener("load", loadCallback);
    el.addEventListener("error", errorCallback);
    document.head.appendChild(el); // 节点插入
  });
}
