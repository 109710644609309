import axios from "axios";
import { Dialog } from "vant";
let isHttp = window.location.protocol == 'http:' ? 'http://' : 'https://';
let domainName = window.location.hostname;
//创建一个axios实例
const instance = axios.create();
instance.defaults.timeout = 15000; // 请求超时时间
instance.defaults.baseURL = process.env.VUE_APP_NODE_ENV !== "prod" ? process.env.VUE_APP_BASE_URL : `${isHttp}${domainName}`;
// process.env.NODE_ENV == "development" ? "/api" : `${isHttp}${domainName}/rest/`;

// 请求添加条件，如token
instance.interceptors.request.use(
  (config) => {
    config.headers["authorization"] = window._ICC_LBS_DATA.token || '';
    // 默认星漫
    process.env.VUE_APP_NODE_ENV !== "prod" ? config.headers["wxCorpId"] = 'ww561ce81776c525cd' : '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // console.log(response, 'response');
    // if (response.data.errCode !== 0) {
    //   if (response.data && response.data.errCode == 401) {
    //     Dialog.alert({
    //       message: `登录信息已失效，请重新登陆`,
    //     }).then((res) => { });
    //     return {
    //       errCode: response.data.errCode,
    //       errMsg: response.data.errMsg,
    //       successful: false,
    //     };
    //   } else if (
    //     (response.data && response.data.errCode == 10001) ||
    //     (response.data && response.data.errCode == 1)
    //   ) {
    //     Dialog.alert({
    //       message: response.data.errMsg || `系统繁忙`,
    //     });
    //     return {
    //       errCode: response.data.errCode,
    //       errMsg: response.data.errMsg,
    //       successful: false,
    //     };
    //   } else {
    //     Dialog.alert({
    //       message: response.data.errMsg || `系统繁忙`,
    //     });
    //     return {
    //       errCode: response.data.errCode,
    //       errMsg: response.data.errMsg,
    //       successful: false,
    //     };
    //   }
    // }
    if (response.data && ['401', 401, '10001', 10001].indexOf(response.data.errCode) !== -1) {
      // token失效在此操作
      Dialog.alert({
        message: `登录信息已失效，请重新登陆`,
      }).then(() => {
        window._ICC_LBS_DATA.token = null;
        storage.removeStorage('token', 'localStorage', getCallbackUrl);
      });
      return Promise.reject(response.data);
    }

    // 暂无权限
    if (response.data && ['403', 403].indexOf(response.data.errCode) !== -1) {
      return Promise.reject(response.data);
    }

    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    console.log("错误回调", error);
    if (error.message.includes("timeout")) {
      // 判断请求异常信息中是否含有超时timeout字符串
      Dialog.alert({
        message: "网络请求超时，请稍后再试！",
      });
    }
    if (error.message.includes("Network Error")) {
      Dialog.alert({
        message: "网络请求超时，请稍后再试！",
      });
    }
    // 对响应错误做点什么
    return Promise.reject({
      errCode: 500,
      message: "系统繁忙，请稍后再试！",
      successful: false,
    });
  }
);
export default instance;
